<template>
  <div>
    <a-row :gutter="16">
      <a-col :md="24" :lg="14">
        <a-form-item required :colon="false" label="Years">
          <a-input-group size="large">
            <a-row type="flex" align="middle">
              <a-col :span="11">
                <a-input-number
                  :min="2010"
                  :max="2100"
                  size="large"
                  class="w-100"
                  v-model="dataSchoolYear.tahun_awal"
                  @change="(e) => handleChange(e, 'tahun_awal')"
                />
              </a-col>
              <a-col :span="2" class="text-center">-</a-col>
              <a-col :span="11">
                <a-input-number
                  :max="2100"
                  disabled
                  size="large"
                  class="w-100"
                  :value="dataSchoolYear.tahun_akhir"
                  @change="(e) => handleChange(e, 'tahun_akhir')"
                />
              </a-col>
            </a-row>
          </a-input-group>
          <!-- <a-range-picker
            :value="tahunArr"
            :mode="['year', 'year']"
            size="large"
            class="w-100"
            :placeholder="['Start Year', 'End Year']"
            @change="(e) => handleChange(e, 'tahun')"
          /> -->
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :md="24" :lg="24">
        <a-form-item required :colon="false" label="Semester">
          <a-radio-group size="large" class="w-100" :value="dataSchoolYear.semester" button-style="solid" @change="e => handleChange(e.target.value, 'semester')">
            <a-radio-button class="w-50 text-center" value="Ganjil">
              First
            </a-radio-button>
            <a-radio-button class="w-50 text-center" value="Genap">
              Second
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :colon="false" v-if="dataSchoolYear.semester === 'Ganjil' && formStatus.isCreate && isAvailable" label="Duplicate" >
          <div class="p-3 border rounded">
            <a-checkbox @change="(e) => handleChange(e.target.checked, 'duplicate')" v-model="dataSchoolYear.statusDuplicate">
              Duplicate Classes From {{dataSchoolYear.tahun_awal ? dataSchoolYear.tahun_awal - 1 : ''}} {{dataSchoolYear.tahun_awal && dataSchoolYear.tahun_akhir ? '-' : ''}} {{dataSchoolYear.tahun_akhir ? dataSchoolYear.tahun_akhir - 1 : ''}}{{dataSchoolYear.tahun_awal && dataSchoolYear.tahun_akhir ? "'s" : ''}} Second Semester
            </a-checkbox>
          </div>
        </a-form-item>
        <a-form-item :colon="false" v-if="dataSchoolYear.semester === 'Genap' && formStatus.isCreate && isAvailable" label="Duplicate" >
          <div class="p-3 border rounded">
            <a-checkbox @change="(e) => handleChange(e.target.checked, 'duplicate')" v-model="dataSchoolYear.statusDuplicate">
              Duplicate Classes and Schedules From First Semester
            </a-checkbox>
          </div>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 0]">
      <a-col :md="24" :lg="14">
        <a-form-item required :colon="false" label="Date Active">
          <a-range-picker
            :value="[dataSchoolYear.tanggal_awal, dataSchoolYear.tanggal_akhir]"
            :disabled="!dataSchoolYear.tahun_awal"
            :disabledDate="disabledDate"
            format="DD-MM-YYYY"
            size="large"
            class="w-100"
            :placeholder="['Start Date', 'End Date']"
            @change="(e) => handleChange(e, ['tanggal_awal', 'tanggal_akhir'])"
          />
        </a-form-item>
      </a-col>
      <a-col :md="24" :lg="10">
        <a-form-item label="Week">
          <a-input
            disabled
            size="large"
            class="w-100"
            :value="week"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    dataSchoolYear: {
      type: Object,
      required: true,
    },
    formStatus: {
      type: Object,
      required: true,
    },
    allDataSchoolYear: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      week: 0,
      now: moment(),
    }
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
    disabledDate(current, old) {
      if (current) {
        // const condition = this.dataSchoolYear.tanggal_awal ? current < this.dataSchoolYear.tanggal_awal : false
        return current && (current.format('YYYY') < this.dataSchoolYear.tahun_awal || current.format('YYYY') > this.dataSchoolYear.tahun_akhir || current.format('dddd') === 'Saturday' || current.format('dddd') === 'Sunday')
      }
      // return current && current < this.dataSchoolYear.tanggal_awal
    },
  },
  computed: {
    isAvailable() {
      if (this.dataSchoolYear.semester === 'Ganjil') {
        if (this.dataSchoolYear.tahun_awal) {
          const tahunAjaranLalu = `${this.dataSchoolYear.tahun_awal - 1} - ${this.dataSchoolYear.tahun_akhir - 1}`
          const isExist = this.allDataSchoolYear.find(year => {
            return year.semesters.find(semester => semester.semester === 'Genap' && semester.tahun === tahunAjaranLalu)
          })
          return Boolean(isExist)
        }
      } else if (this.dataSchoolYear.semester === 'Genap') {
        const tahunAjaran = `${this.dataSchoolYear.tahun_awal} - ${this.dataSchoolYear.tahun_akhir}`
        const isExist = this.allDataSchoolYear.find(year => {
          return year.semesters.find(semester => semester.semester === 'Ganjil' && semester.tahun === tahunAjaran)
        })
        return Boolean(isExist)
      }

      return false
    },
  },
  watch: {
    'dataSchoolYear.tahun_akhir'(newVal) {
      // console.log(newVal, 'watch')
    },
    'dataSchoolYear.tanggal_akhir'(newVal) {
      const diff = moment(newVal, 'YYYY-MM-DD').diff(moment(this.dataSchoolYear.tanggal_awal, 'YYYY-MM-DD'), 'week')
      this.week = isNaN(diff) ? 0 : diff + 1 // karena diff tidak menganggap minggu awal dalam hitungan
    },
    isAvailable(newValue) {
      if (!newValue) {
        this.$emit('handle-change', { value: false, column: 'statusDuplicate' })
      } else {
        console.log('masuk')
        this.$emit('handle-change', { value: true, column: 'statusDuplicate' })
      }
    },
  },
  created() {
    console.log(this.dataSchoolYear)
    const diff = moment(this.dataSchoolYear.tanggal_akhir, 'YYYY-MM-DD').diff(moment(this.dataSchoolYear.tanggal_awal, 'YYYY-MM-DD'), 'week')
    // console.log(newVal, 'tes', diff, this.dataSchoolYear.tanggal_awal)
    this.week = isNaN(diff) ? 0 : diff
  },
}
</script>

<style>
</style>
